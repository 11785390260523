import React, { useState, useEffect } from 'react';
import {
    getGDSNFeeById,
    getGDSNOperationMode,
    getGDSNReceiver,
    createGDSNInfo,
  } from '../services/registrationService';
  import { priceFormatter } from '../utils/priceFormatter';
  import { toast } from 'react-toastify';
  import { useNavigate } from 'react-router-dom';

const Gdsn = () => {
  const [formValues, setFormValues] = useState({
    pmodel: '',
    gtinrange: '',
    fee: '',
    receivers: [],
  });
  const [gtinRangeList, setGtinRangeList] = useState(['<10', '11-20', '21-30']);
  const [receiverList, setReceiverList] = useState([]);
  const [modeList, setModeList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getGDSNOperationMode().then((res) => {
      setModeList(res.data.data);
    });

    getGDSNReceiver().then((res) => {
      setReceiverList(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (formValues.gtinrange) {
      getGDSNFeeById(formValues.gtinrange).then((res) => {
        var response = res.data;
        setFormValues((prev) => ({
          ...prev,
          fee: priceFormatter(response.data.price, 'EUR'),
        }));
      });
    }
  }, [formValues.gtinrange]);


  const sessionData = JSON.parse(sessionStorage.getItem("company")) || {};
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (id) => {
    setFormValues((prev) => {
      const newReceivers = prev.receivers.includes(id)
        ? prev.receivers.filter((receiver) => receiver !== id)
        : [...prev.receivers, id];
      return { ...prev, receivers: newReceivers };
    });
  };

  const handleSubmit = (e) => {
    if (!formValues.pmodel || !formValues.gtinrange) {
      toast.error('Please fill all required fields');
      return;
    }
    e.preventDefault();
    const payload = {
      companyId: sessionData.id,
      registrationId: sessionData.registrationid,
      gtinRange: formValues.gtinrange,
      gdsnFeeId: 0,
      operationModeId: formValues.pmodel,
      receiverIds: formValues.receivers,
      id: 0,
    };

    createGDSNInfo(payload).then((res) => {
      console.log(res);
      if (res.data.isSuccess) {
        toast.success('GDSN Request submitted successfully');
      }
      else{
        toast.error('Error submitting request');
      }

      navigate('/user/home');
    }).catch((error) => {
      toast.error('You have a pending request');
    });
  };

  return (
    <div className="gdsnsection mt-5 GDSN">
      <div className="col-12">
        <div className="card ml-2 pl-3 pt-3 pr-2 mb-5">
          <div className="mid-info">
            <h4>GDSN Request Information</h4>
          </div>
    
          <div className="formwrapper mt-5 p-3">
            <form onSubmit={handleSubmit} className="row" style={{ margin: '1px' }}>
              <div className="row" style={{ width: '100%' }}>
                <div className="form-group gdsn col-md-6">
                  <label>
                    Choose Participation Model: <span className="text-danger">*</span>
                  </label>
                 { modeList.map((mode, index) => (
                    <div key={index} className="form-check">
                      <input
                        className="mr-3"
                        type="radio"
                        name="pmodel"
                        value={index + 1}
                        onChange={handleChange}
                        checked={formValues.pmodel === String(index + 1)}
                      />
                      <label>{mode.operationMode}</label>
                    </div>
                  ))}
                </div>
                <div className="form-group col-md-6">
                  <label>
                    GTIN Range <span className="text-danger">*</span>
                  </label>
                  <select
                    name="gtinrange"
                    className="form-control form-control-lg"
                    style={{ fontSize: '14px' }}
                    value={formValues.gtinrange}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select range
                    </option>
                    {gtinRangeList.map((range, index) => (
                      <option key={index} value={index + 1}>
                        {range}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <label>Fee</label>
                  <input
                    name="fee"
                    type="text"
                    readOnly
                    value={formValues.fee}
                    className="form-control mb-3 form-control-lg"
                    placeholder=""
                  />
                </div>
              </div>

              {formValues.pmodel === '1' && (
                <div className="row mt-3 d-flex flex-column" style={{ width: '100%' }}>
                  <div className="d-flex">
                    <label>
                      Select Receivers: <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="ml-5">
                    {receiverList.map((receiver) => (
                      <div key={receiver.id}>
                        <input
                          style={{ width: '20px', height: '20px' }}
                          className="mr-3"
                          type="checkbox"
                          checked={formValues.receivers.includes(receiver.id)}
                          onChange={() => handleCheckboxChange(receiver.id)}
                        />
                        <label>{receiver.receiver}</label>
                      </div>
                    ))}
                  </div>
                
                </div>
              )}

<button type="submit" disabled ={!formValues.fee}  className="btn col-2 btn-primary mt-3">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gdsn;
