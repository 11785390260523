import React from "react";
import { FaBarcode } from "react-icons/fa";
import { FaMoneyBill } from "react-icons/fa";
import { AiOutlineBarcode } from "react-icons/ai";
import { RiBarcodeLine } from "react-icons/ri";
import { AiOutlineCalendar } from "react-icons/ai";
import { BsBoxSeam } from "react-icons/bs";
import { BsBoxSeamFill } from "react-icons/bs";
import brand_img from "../images/brand.png";
import gtin_img from "../images/gengtin.png";
import upload_img from "../images/upload.png";
import additional_img from "../images/additional.png";
import barcode_img from "../images/barcodeimg.png";
import newproduct_img from "../images/newproduct.png";
import itf_img from "../images/itf.png";
import itf_create from "../images/itf-create.png";
import itf_request from "../images/itf-request.png";
import gln_img from "../images/gln.png";
import gln_imgB from "../images/gln_1.png";
import service3 from "../images/service3.png";
import service4 from "../images/service4.png";

import account from "../images/account.png";
import { HashLoader } from "react-spinners";

import { MdNotifications } from "react-icons/md";
import { useProductListingContext } from "../context/productListingContext";
import { useUserContext } from "../context/userContext";
import { CChart } from "@coreui/react-chartjs";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import {
  CRow,
  CCol,
  CContainer,
  CCard,
  CWidgetStatsC,
  CWidgetStatsF,
  CCardBody,
  CCardTitle,
  CCardText,
  CModal,
  CButton,
  CModalBody,
  CHeader,
  CModalFooter,
  CModalTitle,
  CModalHeader,
  CFormInput,
} from "@coreui/react";
import { Link } from "react-router-dom";

const Home = () => {

  const {
    totalGtinsRequested,
    companyname,
    usedGtins,
    remainingGtins,
    usedImages,
    remainingImages,
    pendingImageRequest,
    totalUsed,
    totalRemaining,
    totalImagesRequested,
    nextRenewalAmount,
    nextPaymentDate,
    pendingGCPStatus,
    registrationid
  } = useUserContext();
  const { brands, products } = useProductListingContext();
  const [visible, setVisible] = useState(false);
  const [glnCount, setGlnCount] = useState("");
  const [loadingNow, setLoadingNow] = useState(false);
  const [itfloadingNow, setITFLoadingNow] = useState(false);
  const [requestingITF, setReuestingITF] = useState(false);
  const [ITFQuantity, setITFQuantity] = useState(0);


  let split_time = "";
  let payday = "";
  if (nextPaymentDate) {
    split_time = nextPaymentDate?.split("T");
    payday = split_time[0];
  }

  const remGtin = "GTINs remaining of " + totalGtinsRequested;
  const amount = nextRenewalAmount;
  const amountFormatted = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(amount);

  const getPercentage = (used, total) => {
    const cal = (used / total) * 100;
    return Math.ceil(cal);
  };
  const getPercentagePaint = (used, total) => {
    const cal = getPercentage(used, total);
    console.log("cal", cal);
    if (cal > 70) {
      // console.log("cal", cal);
      return "danger";
    } else if (cal <= 60 && cal >= 50) {
      return "warning";
    } else {
      return "success";
    }
  };

  const getCardImgColor = () => {
    switch (totalImagesRequested) {
      case 0:
        return "warning";
      default:
        return "";
    }
  };
  const showModal = () => {
    return (
      <CModal>
        <CModalHeader>
          <CModalTitle>React Modal title</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>React Modal body text goes here.</p>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary">Close</CButton>
          <CButton color="primary">Save changes</CButton>
        </CModalFooter>
      </CModal>
    )
  }
  const createProducts = () => {
    if (remainingGtins > 0) {
      if (brands.length > 0) {
        return (
          <Link
            className="gtin-link px-2 text-[13px] font-bold"
            to="/user/creategtin"
          >
            Create product
          </Link>
        );
      } else {
        return (
          <Link className=" text-[13px] font-bold" to="/user/managebrands">
            create brand
          </Link>
        );
      }
    } else {
      return (
        <Link className="" to="">
          No active GTINS
        </Link>
      );
    }
  };
  const imageToShow = () => {
    if (totalImagesRequested == 0) {
      return (
        <p className="text-medium-emphasis">
          You do not have any barcode image
        </p>
      );
    } else {
      return (
        <p className="ext-high-emphasis fs-4 fw-semibold">{remainingImages}</p>
      );
    }
  };
  const getImageTitle = () => {
    if (totalImagesRequested == 0) {
      return <></>;
    } else {
      return (
        <p className="text-medium-emphasis">
          Images remaining of {totalImagesRequested}
        </p>
      );
    }
  };

  const nigerianCurrencyFormat = new Intl.NumberFormat("en-NG", {
    currency: "NGN",
    style: "currency",
  });

  const showITFRequest = () => {
    return (
      <div className="mx-4 my-6 ">
        <CModal

          visible={requestingITF}
          onClose={() => setReuestingITF(false)}

        >
          <CModalHeader onClose={() => setReuestingITF(false)}>
            <h1 className="text-[16px] font-bold">
              Enter the quantity of ITF-14 you need: <br />
              <span className="font-semibold text-[14px] text-gs1orange">
                Cost will appear here:{" "}
                {nigerianCurrencyFormat.format(ITFQuantity * 5000)} <br />
                <p className="text-black text-[13px]">
                  Account Name: GS1 System Nigeria. Account Number:2015342894,
                  <br /> Bank: FBN{" "}
                </p>
              </span>
            </h1>


          </CModalHeader>

          <CModalBody>
            <input
              className="w-full outline outline-2 outline-offset-0 h-[35px] px-2 border-b-2 border-gs1-blue"
              type="number"
              id="exampleFormControlInput1"
              label="Enter Quantity"
              placeholder=""
              onChange={(e) => setITFQuantity(e.target.value)}
            // aria-describedby="exampleFormControlInputHelpInline"
            />
          </CModalBody>
          <CModalFooter>
            <button
              className="h-[36px] w-[58px] px-1 text-[14px] bg-gs1-color-orange text-gs1-color-white"
              color="secondary"
              onClick={(e) => setReuestingITF(false)}
            >
              Close
            </button>
            <button
              disabled={itfloadingNow}
              className="h-[36px] text-[14px] px-1 bg-gs1-blue w-[140px] text-gs1-color-white"
              onClick={sendGITFRequest}
              color="primary"
            >
              Save changes {""}
              <span>
                {itfloadingNow && (
                  <HashLoader className="ml-3" color="#ffffff" size={22} />
                )}
              </span>
            </button>
          </CModalFooter>
        </CModal>
      </div>
    )
  }

  const sendGITFRequest = async () => {
    if (ITFQuantity > 0) {
      setITFLoadingNow(true);
      try {
        // dispatch({ type: "SAVE_PRODUCTS" });
        await axios({
          url: `${process.env.REACT_APP_BASE_URL}itf14/requestitf`,
          method: "POST",
          data: { ITFQuantity, registrationid },
        })
          .then((res) => {
            if (
              res.data.isSuccess ==
              true
            ) {
              toast.success(
                "Successfully sent request information for approval."
              );
              setReuestingITF(false);
              setITFLoadingNow(false);
            } else {
              toast.warn(res.data.message);
              setITFLoadingNow(false);
            }
          })
          .catch((er) => {
            toast.warn("Please try again after some time");
            toast.error("Please retry");
            setITFLoadingNow(false);
          });
      } catch (err) {
        toast.error("Please retry");
      }
    } else {
      toast.warn("Please enter a valid number");
      setITFLoadingNow(false);
    }
  };

  const sendGLnRequest = async () => {
    if (glnCount > 0) {
      setLoadingNow(true);
      try {
        // dispatch({ type: "SAVE_PRODUCTS" });
        await axios({
          url: `${process.env.REACT_APP_BASE_URL}glnrequest/create`,
          method: "POST",
          data: { glnCount },
        })
          .then((res) => {
            if (
              res.data.message ==
              "Successfully sent location information for approval."
            ) {
              toast.success(
                "GLN REQUEST SAVED! kindly wait for an approval email"
              );
              setVisible(false);
              setLoadingNow(false);
            } else {
              toast.warn(res.data.message);
              setVisible(false);
            }
          })
          .catch((er) => {
            toast.warn("Please try again after some time");
            toast.error("Please retry");
            setLoadingNow(false);
          });
      } catch (err) {
        toast.error("Please retry");
      }
    } else {
      toast.warn("Please enter a valid number");
      setLoadingNow(false);
    }
  };

  return (

    <div className="mx-4 my-6 ">
  
      <CModal
        // className=" border-t-2 border-gs1-blue"
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="LiveDemoExampleLabel"
      >
        <CModalHeader onClose={() => setVisible(false)}>
          <h1 className="text-[16px] font-bold">
            Enter the quantity of GLNs you need: <br />
            <span className="font-semibold text-[14px] text-gs1orange">
              Cost will appear here:{" "}
              {nigerianCurrencyFormat.format(glnCount * 70000)} <br />
              <p className="text-black text-[13px]">
                Account Name: GS1 System Nigeria. Account Number:2015342894,
                <br /> Bank: FBN{" "}
              </p>
            </span>
          </h1>

          {/* <p>Cost will appear here:</p> */}
          {/* <CModalTitle
            id="LiveDemoExampleLabel"
            className="text-[16px] font-bold"
          >
            Enter the Quantity of GLN you Need
          </CModalTitle>{" "} */}
        </CModalHeader>

        <CModalBody>
          <input
            className="w-full outline outline-2 outline-offset-0 h-[35px] px-2 border-b-2 border-gs1-blue"
            type="number"
            id="exampleFormControlInput1"
            label="Enter Quantity"
            placeholder="1-10"
            onChange={(e) => setGlnCount(e.target.value)}
          // aria-describedby="exampleFormControlInputHelpInline"
          />
        </CModalBody>
        <CModalFooter>
          <button
            className="h-[36px] w-[58px] px-1 text-[14px] bg-gs1-color-orange text-gs1-color-white"
            color="secondary"
            onClick={(e) => setVisible(false)}
          >
            Close
          </button>
          <button
            disabled={loadingNow}
            className="h-[36px] text-[14px] px-1 bg-gs1-blue w-[140px] text-gs1-color-white"
            onClick={sendGLnRequest}
            color="primary"
          >
            Save changes {""}
            <span>
              {loadingNow && (
                <HashLoader className="ml-3" color="#ffffff" size={22} />
              )}
            </span>
          </button>
        </CModalFooter>
      </CModal>
      <h1 className="text-[17px] font-bold">My Information</h1>
      <div className="h-[250px] bg-gs1-blue rounded-xl grid lg:grid-cols-3 sm:grid-cols-1 sm:my-4">
        <div>
          {" "}
          <div className="text-gs1-color-white text-[1em] px-4 py-4">
            Hello {companyname},
            <br />
            <span className="lg:text-[12px] sm:text-[1em]">
              welcome to your dashboard. <br />
              <hr />
              Your total GTINS is:
              <span className="font-bold">
                {""} {totalGtinsRequested}
              </span>
              <br />
              You have <b>{remainingGtins}</b> GTINS & <b>{remainingImages}</b>{" "}
              Images left
            </span>
            <hr />
            <div className="d-flex flex-row justify-content-between">
              <div>
                <span className="text-[12px] font-medium">
                  Used GLN: <b>{totalUsed}</b> <br />
                  Remaining GLN: <b>{totalRemaining}</b>
                </span>
              </div>
              <div className="d-none">
                <span className="text-[12px] font-medium">
                  Used GLN: <b>{totalUsed}</b> <br />
                  Remaining GLN: <b>{totalRemaining}</b>
                </span>
              </div>
            </div>

          </div>
          {/* <div className="mx-4 h-[57px] border-2  border-gs1-color-white rounded-md">
            <p className="text-gs1-color-white text-[13px] px-2 py-1">
              Account Name: GS1 Systems Nigeria, Account Number: 2015342894,
              Bank Name:FBN{" "}
            </p>
          </div> */}
        </div>
        <div className="col-span-2">
          <div className="grid lg:grid-cols-4 sm:grid-cols-2 sm:gap-2">
            <div className="h-[200px] bg-gs1gold mt-4 lg:w-[200px] sm:w-[170px] px-2 py-4 rounded-md">
              {<FaMoneyBill size={40} color="white" />}
              <h1 className="text-white text-[18px] font-bold">
                {amountFormatted}
              </h1>
              <p className="text-gs1-color-white ">Next renewal<br /> Amount</p>
            </div>
            <div className="h-[200px] bg-gs1-color-grass mt-4 lg:w-[200px] sm:w-[170px] px-2 py-4 rounded-md">
              {<AiOutlineCalendar size={40} color="white" />}
              <h1 className="text-white text-[18px] font-bold">{payday}</h1>
              <p className="text-gs1-color-white ">Next payment <br />date</p>
            </div>
            <div className="h-[200px] bg-gs1-color-purple-accessible  mt-4 lg:w-[200px] sm:w-[170px] px-2 py-4 rounded-md">
              {<MdNotifications size={40} color="white" />}
              <h1 className="text-white text-[18px] font-bold">
                {pendingGCPStatus ? 1 : 0}
              </h1>
              <p className="text-gs1-color-white ">Pending GCP</p>
            </div>
            <div className="h-[200px] bg-gs1-color-link  mt-4 lg:w-[200px] sm:w-[170px] px-2 py-4 rounded-md">
              {<MdNotifications size={40} color="white" />}
              <h1 className="text-white text-[25px] font-bold">
                {pendingImageRequest ? 1 : 0}
              </h1>
              <p className="text-gs1-color-white ">Pending image</p>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:my-4 sm:mt-96">
        <h1 className="text-[17px] font-bold">GTIN SERVICES</h1>
        <div className="grid lg:grid-cols-6 sm:grid-cols-2 lg:gap-2 sm:gap-1 ">
          <div className="h-[210px] bg-gs1-color-white shadow-md rounded-md px-2">
            <div className="columns-2">
              <div>
                <img src={brand_img} className="h-[90px]" alt="" />
              </div>
              <div className="h-10 bg-gs1orange w-10 rounded-lg px-2 py-2 text-gs1-color-white font-bold ">
                {brands !== "" ? brands.length : 0}
              </div>
            </div>
            <p className="px-2 text-[11px] font-medium">
              A unique and identifiable name for your product
            </p>
            <Link
              to="/user/managebrands"
              className="px-2 text-[13px] font-bold"
            >
              Manage brands
            </Link>
          </div>
          <div className="h-[210px] bg-gs1-color-white shadow-md rounded-md px-2 ">
            <div className="columns-2">
              <div>
                {" "}
                <img src={newproduct_img} className="h-[90px]" alt="" />
              </div>
              <div className="h-10  rounded-lg px-2 py-2 text-gs1-color-white font-bold ">
                {/* {products !== "" ? products.length : 0} */}
              </div>
            </div>
            <p className="px-2 text-[11px] font-medium">
              Generate GTIN(s) for your product
            </p>
            {createProducts()}
          </div>
          <div className="h-[210px] bg-gs1-color-white shadow-md rounded-md px-2 ">
            <div className="columns-2">
              <div>
                {" "}
                <img src={gtin_img} className="h-[90px]" alt="" />
              </div>
              <div className="h-10 bg-gs1orange w-10 rounded-lg px-2 py-2 text-gs1-color-white font-bold ">
                {products !== "" ? products.length : 0}
              </div>
            </div>
            <p className="px-2 text-[11px] font-medium">
              View your already listed products
            </p>
            <Link
              to="/user/viewproducts"
              className="px-2  text-[13px] font-bold"
            >
              View products
            </Link>
          </div>
          <div className="h-[210px] bg-gs1-color-white shadow-md rounded-md px-2 ">
            <img src={upload_img} className="h-[90px]" alt="" />
            <p className="px-2 text-[11px] font-medium">
              Upload bulk product information using EXCEL.
            </p>
            <Link
              to="/user/productupload"
              className="px-2  text-[13px] font-bold">Upload products</Link>
          </div>
          <div className="h-[210px] bg-gs1-color-white shadow-md rounded-md px-2 ">
            <img src={barcode_img} className="h-[90px]" alt="" />
            <p className="px-2 text-[11px] font-medium">
              Buy a barcode image(s) for your product
            </p>
            <Link
              to="/user/buybarcodeimage"
              className="px-2  text-[13px] font-bold"
            >
              Buy image
            </Link>
          </div>
          <div className="h-[210px] bg-gs1-color-white shadow-md rounded-md px-2 ">
            <img src={additional_img} className="h-[90px]" alt="" />
            <p className="px-2 text-[11px] font-medium">
              Get additional barcode(s) for your product
            </p>
            <Link
              to="/user/additionals"
              className="px-2  text-[13px] font-bold"
            >
              Additional barcode
            </Link>
          </div>
        </div>
      </div>
      {/* GLN SECTION */}
      <div className="lg:my-4 sm:my-2">
        <h1 className="text-[17px] font-bold mt-3">
          GLN SERVICES
        </h1>
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 lg:gap-2 sm:gap-1 ">

          <div className="h-[210px] bg-gs1-color-white shadow-md rounded-md px-2 ">
            <img src={gln_img} className="h-[90px]" alt="" />
            <p className="px-2 text-[11px] font-medium">
              Assign unique GLNs to your locations
            </p>
            <Link to="/user/listglns" className="px-2  text-[13px] font-bold">
              List GLNs
            </Link>
          </div>
          <div className="h-[210px] bg-gs1-color-white shadow-md rounded-md px-2 ">
            <img src={gln_img} className="h-[90px]" alt="" />
            <p className="px-2 text-[11px] font-medium">
              View your already listed GLNs
            </p>
            <Link to="/user/viewglns" className="px-2  text-[13px] font-bold">
              View GLNs
            </Link>
          </div>
          <div className="h-[210px] bg-gs1-color-white shadow-md rounded-md px-2 ">
            <img src={gln_img} className="h-[90px]" alt="" />
            <p className="px-2 text-[11px] font-medium">
              Request for a GLN for your company
            </p>
            <Link
              to=""
              onClick={() => setVisible(!visible)}
              className="px-2  text-[13px] font-bold"
            >
              Request GLNs
            </Link>
          </div>
          {/* <div className="h-[210px] bg-gs1-color-white shadow-md rounded-md px-2 ">
            <img src={gln_img} className="h-[90px]" alt="" />
          </div> */}
          {/* <div className="h-[210px] bg-gs1-color-white shadow- rounded- px-2 ">
            <img src={gln_img} className="h-[90px]" alt="" />
          </div> */}
        </div>
      </div>
      {/* GLN SECTION */}
      <div className="lg:my-4 sm:my-2">
        <h1 className="text-[17px] font-bold mt-3">
          ITF-14 SERVICES
        </h1>
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 lg:gap-2 sm:gap-1 ">

          <div className="h-[210px] bg-gs1-color-white shadow-md rounded-md px-2 ">
            <img src={itf_create} className="h-[90px]" alt="" />
            <p className="px-2 text-[11px] font-medium">
              Request for ITF-14 Services
            </p>
            <Link to=""
              onClick={() => setReuestingITF(true)}
              className="px-2  text-[13px] font-bold">
              Request For ITF-14
            </Link>
            {showITFRequest()}
          </div>
          <div className="h-[210px] bg-gs1-color-white shadow-md rounded-md px-2 ">
            <img src={itf_img} className="h-[90px]" alt="" />
            <p className="px-2 text-[11px] font-medium">
              View the list of ITF-14 you've already created
            </p>
            <Link to="/user/viewitf14s" className="px-2  text-[13px] font-bold">
              View ITF-14
            </Link>
          </div>
          <div className="h-[210px] bg-gs1-color-white shadow-md rounded-md px-2 ">
            <img src={itf_create} className="h-[90px]" alt="" />
            <p className="px-2 text-[11px] font-medium">
              Create ITF-14 barcode for your cartons
            </p>
            <Link
              to="/user/listitf14"
              onClick={() => setVisible(!visible)}
              className="px-2  text-[13px] font-bold"
            >
              Create ITF-14
            </Link>
          </div>
          {/* <div className="h-[210px] bg-gs1-color-white shadow-md rounded-md px-2 ">
            <img src={gln_img} className="h-[90px]" alt="" />
          </div> */}
          {/* <div className="h-[210px] bg-gs1-color-white shadow- rounded- px-2 ">
            <img src={gln_img} className="h-[90px]" alt="" />
          </div> */}
        </div>
      </div>
      <div className="lg:my-4 sm:mt-96">
        <h1 className="text-[17px] font-bold">OTHER SERVICES</h1>
        <div className="grid lg:grid-cols-6 sm:grid-cols-2 lg:gap-2 sm:gap-1 ">
          <div className="h-[210px] bg-gs1-color-white shadow-md rounded-md px-2">
            <div className="columns-2 py-2">
              <div>
                <img src={service3} className="h-[90px]" alt="" />
              </div>
             
            </div>
            <p className="px-2 text-[11px] font-medium">
            The GS1 Global Data Synchronisation Network enables trading partners to globally share trusted product data in real-time.
            </p>
            <Link
              to="/user/gdsn"
              className="px-2 pt-4 mt-4 text-[13px] font-bold"
            >
              Add GDSN
            </Link>
          </div>
          <div className="h-[210px] bg-gs1-color-white shadow-md rounded-md px-2 ">
            <div className="columns-2">
              <div>
                {" "}
                <img src={service4} className="h-[90px]" alt="" />
              </div>
              <div className="h-10  rounded-lg px-2 py-2 text-gs1-color-white font-bold ">
              </div>
            </div>
            <p className="px-2 text-[11px] font-medium">
            The Solution Partner Program offers solution providers training and business opportunities for standards-based solutions to meet industry challenges
            </p>
            <Link
              to="/user/solution-provider"
              className="px-2 pt-4 mt-4 text-[13px] font-bold"
            >
              Become a Solution Provider
            </Link>
          </div>
        </div>
      </div>
      <div className="lg:my-4 sm:my-2">
        <h1 className="text-[17px] font-bold">
          ACCOUNT SETTINGS
        </h1>
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 lg:gap-2 sm:gap-1 ">
          <div className="h-[210px] bg-gs1-color-white shadow-md rounded-md px-2">
            <img src={account} className="h-[90px]" alt="" />
            <p className="px-2 text-[11px] font-medium">
              Change your password to something you can easily remember
            </p>
            <Link to="/user/settings" className="px-2  text-[13px] font-bold">
              Change password
            </Link>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Home;
