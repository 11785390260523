import React, { useEffect, useState } from 'react';
import { MultiSelect } from "react-multi-select-component";
import {
  getSolutionProviderRequest,
  getLocalFeeByOptionId,
  getForeignFeeByOptionId,
  createSolutionProviderRequest,
  getCoreCompetences,
  getAllOptions,
} from '../services/registrationService';
import { priceFormatter } from '../utils/priceFormatter';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const SolutionProvider = () => {
  const [formValues, setFormValues] = useState({
    serviceOption: '',
    coreCompetence: [],
    fee: '',
  });

  const [serviceOptions, setServiceOptions] = useState([]);
  const [coreCompetenceList, setCoreCompetenceList] = useState([]);
  const [selectedCompetences, setSelectedCompetences] = useState([]);
  const navigate = useNavigate();

 

  useEffect(() => {
     getAllOptions().then((res) => {
      setServiceOptions(res.data.data);
    });

  

    getCoreCompetences().then((res) => {
      var options = res.data.data.map((option) => ({
        label: option.competence, value: option.id
      }));
      setCoreCompetenceList(options);
    });
  }, []);



  const sessionData = JSON.parse(sessionStorage.getItem("company")) || {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    
      if(sessionData.countries["name"] === 'Nigeria'){
        console.log(sessionData.countries["name"])
        getLocalFeeByOptionId(value).then((res) => {
          var response = res.data;
          console.log(response);
          setFormValues((prev) => ({
            ...prev,
            serviceOption: value,
            fee: priceFormatter(response.data.registrationFee),
          }));
        });
      }
      else{
        getForeignFeeByOptionId(value).then((res) => {
          var response = res.data;
          setFormValues((prev) => ({
            ...prev,
            serviceOption: value,
            fee: priceFormatter(response.data.registrationFee),
          }));
        });
      }
  };

  const handleCoreCompetenceChange = (selectedItems) => {
    
    setSelectedCompetences(selectedItems);
    // const value = Array.from(e.target.selectedOp{tions, (option) => option.value);
    var selectedIds = selectedItems.map((item) => ({
        competenceId : item.value
    }));
    console.log(selectedIds);
    setFormValues((prev) => ({ ...prev, coreCompetence: selectedIds }));
  };

  const handleSubmit = async (e) => {
    if(!formValues.serviceOption || formValues.coreCompetence.length === 0){
      toast.error('All fields are required');
      return;
    }
    e.preventDefault();
    const payload = {
      registrationId: sessionData.registrationid,
      companyId: sessionData.id,
      spOptionId: formValues.serviceOption,
      solutionProviderCompetences: formValues.coreCompetence,
    };
    createSolutionProviderRequest(payload).then((res) => {
      if (res.data.isSuccess)
      {
        toast.success('Request submitted successfully')
      }
      else{
        toast.error('Error submitting request');
      }
      navigate('/user/home'); 
    }).catch(
    (error) => {
      toast.error('You have a pending request');
      return;
    });

  };

  return (
    <div className="solutionProviderSection mt-5 SolutionProvider">
      <div className="col-12">
        <div className="card ml-2 pl-3 pt-3 pr-2 mb-5">
          <div className="mid-info">
            <h4>Solution Provider Request Information</h4>
          </div>
      
          <div className="formwrapper mt-5 p-3">
            <form onSubmit={handleSubmit} className="row" style={{ margin: '1px' }}>
              <div className="row" style={{ width: '100%' }}>
                <div className="form-group col-md-6">
                  <label>
                    Service Options <span className="text-danger">*</span>
                  </label>
                  <select
                    name="serviceOption"
                    className="form-control form-control-lg"
                    value={formValues.serviceOption}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {serviceOptions.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.optionName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row" style={{ width: '100%' }}>
                <div className="form-group mt-4 col-md-6">
                  <label>
                    Core Competence <span className="text-danger">*</span>
                  </label>
                  <MultiSelect
                   options={coreCompetenceList}
                    value={selectedCompetences}
                    onChange={handleCoreCompetenceChange}
                    labelledBy="Select"
                    // onChange={handleCoreCompetenceChange}
                  />
                    
                </div>
              </div>

              <div className="form-group mt-4 col-md-6">
                <label>Fee</label>
                <input
                  name="fee"
                  type="text"
                  readOnly
                  value={formValues.fee}
                  className="form-control mb-3 form-control-lg"
                  placeholder=""
                />
              </div>
                  <div className='row form-group ml-10'>
                    <button type="submit" disabled={!formValues.fee} className="col-md-1 btn btn-primary mt-4">
                Submit
              </button></div>
              
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionProvider;
